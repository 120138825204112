<template src="./OrderAdd.html"></template>

<script>

import Hall from "@/components/hall/Hall"
import {mapGetters} from "vuex";

export default {
  name: "OrderAdd",
  components:{Hall},

  data: () => ({

    event:{},
    eventloaded: true,

    order_comment:"",
    isBesnal:false,

    result:'',

    panel:[0,1],

    selected_table:[],


    hallConfig:{
      base:{
        type: 'adm',
        mode: 'event',
        view: 'monger',
        event_id:0,
      },
      options:{
        zone:true,
        discount:false,
        armour:false,
        puskin:false

      },
      additional:{
        zone_n:0,

        monger_mode: "show_all",
        monger_id:0,
        seat_status_id:91
      }
    } //hallConfig

  }),

  created(){

  },

  computed:{
    ...mapGetters(["getEnvoy", "getMongerlist", "getColorZones", "getEvents"]),

    getOnlyActualMongers(){

      let mongers_id = Object.keys(this.event.stat.mongers);
      return this.getMongerlist.filter(monger=>mongers_id.includes(monger.id.toString()));
    },

    getPricesColors(){

      //console.log(this.getColorZones);

      let price = [];

      let prices = this.event.event.prices.split('/');

      for (let i = 0; i<this.event.price_map.zone_count; i++){

        let t = {};
        t.id = i;
        t.price = prices[i];
        t.color = this.getColorZones[i+1];

        price.push(t);
      }

      //console.log(price)

      return price;
    },




  },

  methods:{

    createMongerOrderGroupLink(){

      console.log('group link');

      let selected = this.$refs.hall.getSelected();
      //console.log(selected);

      let seats = [];

      selected.forEach(seat=>{

        seats.push({
          id :    seat.base.id,
          //zone :  seat.additional.price_zone,
        })


      })

      let data = {

        base:{
          event_id:   this.hallConfig.base.event_id,
          monger_id: this.hallConfig.additional.monger_id,
          user_id:1, // ?
          comment: this.order_comment,
          onlylink: true,
          data_end: "20220225180000"
        },

        seats:seats,
      }

      console.log(data);

      let pr2 = this.$store.state.api.addMongerOrder(data);

      pr2.then( data =>{

        //console.log(data);

        if (data.mo_id>0){

          this.result = "Создана групповая ссылка № "+ data.mo_id;
        }
        else{
          console.log("error set armour");
          console.log(data);
        }
      })


    },

    createMongerOrder(){

      let selected = this.$refs.hall.getSelected();
      //console.log(selected);

      let seats = [];

      selected.forEach(seat=>{

        seats.push({
          id :    seat.base.id,
          //zone :  seat.additional.price_zone,
        })


      })

      let data = {

        base:{
          event_id:   this.hallConfig.base.event_id,
          monger_id: this.hallConfig.additional.monger_id,
          user_id:1, // ?
          type: this.isBesnal,
          comment: this.order_comment,
        },

        seats:seats,
      }

      console.log(data);

      let pr2 = this.$store.state.api.addMongerOrder(data);

      pr2.then( data =>{

        //console.log(data);

        if (data.mo_id>0){

          this.result = "Создан заказ № "+ data.mo_id;

          // обновить зал

          // this.loadEventAdm();
          //
          // this.flagSetMongerArmour = false;
        }
        else{
          console.log("error set armour");
          console.log(data);
        }


      })






    },


    getEventListColor(id){

      if (this.id===id){
        return "#fff2ce";
      }
      else{
        return "#fafafa"
      }

    },

    onLoadedHall(event){

      console.log(event);

      this.event = event.data;

      // let data = {
      //   view: this.hallConfig.base.view,
      //   options: this.hallConfig.options,
      //   additional: this.hallConfig.additional,
      // }
      //
      // this.$refs.hall.updateMode(data);

    },

    onReadyHall(){
      //console.log('ready');
      //this.loadEventAdm();
    },

    loadEventAdm(){
      //console.log('cl');

      this.selected_table = [];
      this.flagSetMongerArmour = false;

      let t = this.prepareDataHall();
      //console.log(t);

      this.$refs.hall.prepareHall(t);


    },

    loadMonger(){

      this.hallConfig.additional.monger_mode = "show_and_select_only";

      let data = {
        view: this.hallConfig.base.view,
        options: this.hallConfig.options,
        additional: this.hallConfig.additional,
      }

      this.$refs.hall.updateMode(data);


    },

    getSelectedSeatDataTable(){

      let selected= this.$refs.hall.getSelected();
      console.log(selected);

      let prices = this.event.event.prices.split('/');

      let selected_table = [];

      let total ={
        zone:0,
        count: 0,
        price: "Итого",
        sum: 0
      }

      for (let i = 1; i<this.event.price_map.zone_count+1; i++){

        let z = selected.filter(seat=>seat.additional.price_zone===i);

        if (!z.length>0){
          continue;
        }

        let t = {
          zone:i,
          count: z.length,
          price: prices[i-1],
          sum: z.length * prices[i-1]
        }

        total.count += t.count;
        total.sum   += t.sum;

        selected_table.push(t);
      }

      selected_table.push(total);

      console.log(selected_table);

      this.selected_table = selected_table;
    },

    prepareDataHall(){

      let data = {

        type: this.hallConfig.base.type,
        mode: this.hallConfig.base.mode,

        view: this.hallConfig.base.view,
        options: this.hallConfig.options,

        additional: this.hallConfig.additional,

        event_id: this.hallConfig.base.event_id,
        scale:0.5

      }

      //console.log(data);

      return data;

    },


  }

}
</script>

<style scoped>

</style>
